<template>
<v-container fluid>
  <v-row class="justify-center">
    <v-col>
      <v-card elevation="0" min-width="500" height="400">
        <div>
          <div class="donut-chart">
            <donut-chart :data="points1" :palette="['SlateGrey', 'green', 'blue', 'orange']"></donut-chart>
          </div>
          <div class="chart-total">
            {{ scoreCounts[0] }}<span class="text-h5">个</span>
            <div class="grey--text">OKR数量</div>
          </div>
          <div class="chart-title">OKR 评分分布</div>
          <!-- <div class="chart-subtitle">
            <v-icon class="mx-2" x-small color="yellow">mdi-lightbulb-on</v-icon><span>{{ subtitle1}}</span>
          </div> -->
          <div class="chart-legend">
            <div @click="selectLegend1(i)" v-for="(btn, i) in points1" class="chart-legend-item">
              <div class="justify-start">{{ btn.name }}</div>
              <div>{{ btn.y }}个</div>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col>
      <v-card elevation="0" min-width="500" height="400">
        <stacked-chart :data="departScore"></stacked-chart>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
export default {
  name: 'OkrScore',
  components: {
    DonutChart: () => import ('@/components/okrdashboard/DonutChart.vue'),
    StackedChart: () => import ('@/components/okrdashboard/StackedChart.vue')
  },
  props: ['scoreCounts', 'departScore'],
  computed: {
    points1: function () {
      return [
        {
          name: '未评分',
          y: this.scoreCounts[1]
        },
        {
          name: '0-0.3分',
          y: this.scoreCounts[2]
        },
        {
          name: '0.4-0.7分',
          y: this.scoreCounts[3]
        },
        {
          name: '0.8-1分',
          y: this.scoreCounts[4]
        }
      ]
    }
  },
  methods: {
    selectLegend1(i) {
      this.$emit('selectLengend', i);
    }
  }
}
</script>